import axios from 'axios'; // 引入axios

let baseUrl = 'https://ratshyh.wzgo.cn';

const zlrtRequestInstance = axios.create({
  baseURL: baseUrl, // 根据项目设置 url = base url + request url
  timeout: 10000 // request timeout
});

export default zlrtRequestInstance;

export function get(url, params) {
  // get封装
  return new Promise((resolve, reject) => {
    zlrtRequestInstance
      .get(url, {
        params: params
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

export function post(url, data) {
  // post封装
  return new Promise((resolve, reject) => {
    zlrtRequestInstance({
      url: url,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer c3fcd3d76192e4007dfb496cca67e13b'
      }
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
