import { get, post } from '@/request/zlrtRequest';

/*export function commonGet(path, params) {
  return get('/port/other/' + path, params);
}
export function commonPost(path, params) {
  return post('/port/other/' + path, params);
}*/
export function loginByMobile(mobile) {
  return post('/zhht/user/zlb/getByMobile', mobile);
}

export function clueReportSave(params) {
  return post('/zhht/clueReport/zlb/save', params);
}
export function clueReportList(params) {
  return post('/zhht/clueReport/zlb/list', params);
}
export function clueReportShow(params) {
  return post('/zhht/clueReport/zlb/one', params);
}

export function mandatoryReportSave(params) {
  return post('/zhht/mandatoryReport/zlb/save', params);
}
export function mandatoryReportList(params) {
  return post('/zhht/mandatoryReport/zlb/list', params);
}
export function mandatoryReportShow(params) {
  return post('/zhht/mandatoryReport/zlb/one', params);
}

export function uploadFile(params) {
  return post('/common/unauth/upload/file', params);
}

export function dictGet(type) {
  return get('/system/dict/data/type/' + type);
}
